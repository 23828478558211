// @codekit-prepend "../node_modules/jquery/dist/jquery.js";

$(document).ready(function() {

    var body = $('body');

    $('.js-toggle-menu').click(function(e) {
        $('body').toggleClass('is-menu-open');
    });

    $('.c-search-btn').click(function(e) {
        $('body').toggleClass('is-search-open');
        $('.is-search-open .l-header .c-search .c-search__input').focus();
    })

    $(window).resize(function() {
        windowSize = $(window).width();
        if (windowSize > 1024) {
            $('body').removeClass('is-menu-open');
        }
    });

});
